.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0 16px;
  & > span:first-child {
    padding: 10px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: var(--theme-font-color);
  }
  & > span:last-child {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #6e6d79;
    padding: 4px 0;
  }
}
.photoReviewSection {
  padding: 0 20px;
  & > h4 {
    display: flex;
    gap: 10px;
    padding: 10px;
    margin-bottom: 16px;
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
  }
  .photoReviewListWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 10px;
    li {
      width: calc(50% - 15px);
    }
  }
  .paginationWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 64px 0;
    .pagination {
      display: flex;
      align-items: center;
      .number {
        display: flex;
        span {
          width: 32px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
          cursor: pointer;
          &.hit {
            font-weight: 600;
            line-height: 17px;
            color: #232323;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 2px;
              left: 6px;
              right: 6px;
              height: 2px;
              background-color: #232323;
            }
          }
        }
      }
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .writeReviewBtn {
      background: #ffffff;
      border: 1px solid var(--theme-color);
      border-radius: 10px;
      padding: 10px 27px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      width: fit-content;
      margin: 0 20px 0;
      cursor: pointer;
    }
  }
}

.normalReviewSection {
  .head {
    background: #f8f9fd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 52px;
    & > span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-font-color);
      padding-left: 10px;
    }
    .moreBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .normalReviewList {
  }
  .paginationAndBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin: 32px 0 64px;
    .pagination {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      .number {
        display: flex;
        span {
          width: 32px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
          cursor: pointer;
          &.hit {
            font-weight: 600;
            line-height: 17px;
            color: #232323;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 2px;
              left: 6px;
              right: 6px;
              height: 2px;
              background-color: #232323;
            }
          }
        }
      }
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .writeReviewBtn {
      background: #ffffff;
      border: 1px solid var(--theme-color);
      border-radius: 10px;
      padding: 10px 27px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      width: fit-content;
      margin: 0 20px 0;
      cursor: pointer;
    }
  }
}

.writeReviewWrap {
  padding: 14px 21px 50px 21px;

  .mobileWriteReviewBtn {
    background: #ff7f00;
    border: 1px solid #ff7f00;
    border-radius: 10px;
    padding: 14px 10px;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .writeReviewBtn {
    background: #ff7f00;
    border: 1px solid #ff7f00;
    border-radius: 10px;
    padding: 14px 10px;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    width: 355px;
    text-align: center;
  }
}
:global(.pc) .title {
  padding: 32px 0 64px;
}
:global(.pc) .writeReviewWrap {
  padding: 0px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
:global(.pc) .photoReviewListWrap {
  display: grid;
  column-gap: 15px;
  row-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  li {
    width: 100%;
  }
}
:global(.pc) .photoReviewSection {
  & > h4 {
    margin-bottom: 32px;
  }
}
:global(.pc) .paginationAndBtn {
  margin: 64px 0 47px;
}
