.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  background-color: var(--theme-color);
  span {
    padding: 10px 1px 10px 47px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
    color: #ffffff;
  }
}
.contentWrap {
  .top {
    padding: 45px 0 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 147px;
      height: 121px;
      margin-bottom: 18px;
    }
    h3 {
      padding: 6px 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
      margin-bottom: 36px;
    }
    .btns {
      display: flex;
      gap: 13px;
      flex-wrap: wrap;
      justify-content: center;
      button {
        width: 160px;
        height: 51px;
        background: rgba(19, 28, 49, 0.1);
        border-radius: 37px;
        box-sizing: border-box;
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--theme-font-color);
        cursor: pointer;
      }
    }
  }
  .withoutBankbookInfo {
    .bankAlertWrap {
      padding: 16px 21px;
      background: #f6f7f9;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .hit {
        font-weight: 600;
      }
    }
    .bankInfoListWrap {
      .bankInfoFrame {
        padding: 20px 21px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-bottom: 12px solid #f6f7f9;
        &:last-child {
          border-bottom: none;
        }
        .titleWrap {
          display: flex;
          gap: 4px;
          font-weight: 700;
          font-size: 20px;
          line-height: normal;
          letter-spacing: -0.5px;
          span:first-child {
            color: #3259e6;
          }
        }
        .bankNum,
        .dateWrap,
        .priceWrap {
          display: flex;
          gap: 18px;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: normal;
          }
          span:first-child {
            letter-spacing: -0.5px;
            color: #999;
          }
        }
        .datePriceWrap {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #999999;
      padding: 15px 0;
    }
    h4 {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: var(--theme-font-color);
      padding: 5px 0;
    }
    ul {
      li {
        display: flex;
        justify-content: center;
        padding: 10px;
        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
        }
      }
    }
  }
  .orderNumberWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 20px 0;
    border-top: 12px solid #f6f7f9;
    border-bottom: 12px solid #f6f7f9;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #999999;
    }
  }
  .orderItemList {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    border-bottom: 12px solid #f6f7f9;
    & > li {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #f6f7f9;
      &:last-child {
        border-bottom: none;
      }
      .productThumbnailWrap {
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        cursor: pointer;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        .productName {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.5px;
          color: #232323;
          padding: 5px 6px;
        }
        .optionListWrap {
          display: flex;
          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(19, 28, 49, 0.5);
            padding: 4px 6px 4px 8px;
            white-space: nowrap;
          }
          ul {
            li {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: rgba(19, 28, 49, 0.5);
              padding: 4px 0;
            }
          }
        }
        .countWrap {
          padding: 4px 10px;
          display: flex;
          gap: 4px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
  .deliveryInfoWrap,
  .reservationInfoWrap {
    padding: 0 20px 20px;
    border-bottom: 12px solid #f6f7f9;
    .sectionTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
      padding: 10px 0;
    }
    .deliveryInfo,
    .reservationInfo {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        flex-wrap: wrap;
        .infoWrap:first-child span:last-child {
          padding-right: 41px;
        }
      }
      .infoWrap {
        display: flex;
        align-items: center;
        min-height: 38px;
        & > span:first-child {
          width: 70px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
          white-space: nowrap;
          flex-shrink: 0;
        }
        & > span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
          display: flex;
          align-items: center;
          .mapBtn {
            margin-left: 6px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              color: #000;
              font-size: 15px;
              font-weight: 500;
            }
            img {
              width: 18px;
              height: 18px;
            }
          }
          .copyBtn {
            margin-left: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              width: 14px;
              height: 14px;
            }
            span {
              color: #ff5656;
              font-size: 15px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .priceInfoWrap {
    padding: 0 20px 20px;
    border-bottom: 12px solid #f6f7f9;
    .sectionTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
      padding: 10px 0;
    }
    .priceInfo {
      display: flex;
      flex-direction: column;
      & > div {
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span:first-child {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: rgba(19, 28, 49, 0.5);
        }
        span:last-child {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
        }
      }
    }
  }
  .totalPrice {
    height: 65px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 12px solid #f6f7f9;
    span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    span:last-child {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      color: var(--theme-font-color);
    }
  }
  .payMethodInfoWrap {
    padding: 30px 20px 43px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
    }
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    .paymethodWrap {
      padding: 20px 0;
      display: flex;
      gap: 20px;
      flex-direction: column;
      span {
        padding: 0;
      }
      .paymethodFrame {
        display: flex;
        gap: 18px;
        .labelWrap {
          font-weight: 400;
          font-size: 15px;
          line-height: normal;
          letter-spacing: -0.5px;
          color: rgba(19, 28, 49, 0.5);
        }
        .contentWrap {
          display: flex;
          flex-direction: column;
          gap: 4px;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: normal;
            letter-spacing: -0.5px;
            color: #131c31;
          }
          span:last-child {
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
