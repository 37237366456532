.bannerSliderWrap {
  // padding: 32px 21px;
  position: relative;
  .bannerSlider {
    // border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      // aspect-ratio: 1/1.2;
      object-fit: cover;
    }
  }
  .paginationWrap {
    position: absolute;
    bottom: 22px;
    left: 22px;
    display: flex;
    gap: 4px;
    z-index: 1;
    li {
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 4px;
      transition: width 0.2s linear;
      cursor: pointer;
      &.hit {
        width: 24px;
      }
    }
  }
}
.processInfoWrap {
  background-size: cover;
  padding: 64px 0px;
  & > h3 {
    padding: 10px 21px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: var(--theme-font-color);
    text-align: center;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-font-color);
    padding: 4px 21px;
    text-align: center;
  }
  & > ul {
    display: flex;
    column-gap: 13px;
    row-gap: 12px;
    flex-wrap: wrap;
    margin-top: 19px;
    padding: 0 21px;
    li {
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      padding: 8px 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processSliderWrap {
    position: relative;
    .processSlider {
      margin-top: 32px;
      padding: 0 21px;

      .processSlide {
        background: #f8f9fd;
        border-radius: 16px;
        box-sizing: border-box;
        aspect-ratio: 1/1.06;
        padding-top: 39px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        & > img {
          width: 145px;
          height: 132px;
        }
        .stage {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 32px;
          span {
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.5px;
            color: #1c1c1c;
          }
          h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.5px;
            color: var(--theme-font-color);
            padding: 10px 0;
          }
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: var(--theme-font-color);
            padding: 4px 0;
          }
        }
      }
    }
    .processSliderPagination {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: var(--theme-color);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid var(--theme-color);
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}
.prescriptionProductWrap {
  padding-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    width: fit-content;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .prescriptionProductSlider {
    padding-left: 21px;
    .prescriptionProductSlide {
      & > img {
        width: 100%;
        aspect-ratio: 1/1.2;
        object-fit: cover;
        border-radius: 16px;
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.normalProductWrap {
  padding: 32px 21px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  .normalProductList {
    display: flex;
    margin-top: 16px;
    li {
      padding-bottom: 14px;
      width: 100%;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        aspect-ratio: 1/0.57;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 148px;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.004em;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .hashtagList {
          padding-bottom: 10px;
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.allProductList {
  padding: 0 21px 32px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-font-color);
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    li {
      width: calc(50% - 10px);
      padding-bottom: 20px;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          aspect-ratio: 1/1.2;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.004em;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.05em;
          color: var(--theme-font-color);
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: var(--theme-font-color);
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 32px;
  }
}
.faqWrap {
  padding: 32px 21px;
  background: #fff3e8;
  margin-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.reviewWrap {
  padding-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0 0 21px;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .reviewSliderWrap {
    margin-top: 32px;
    padding-left: 21px;
    position: relative;
    border-radius: 0;
  }
}
.legitScript {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  position: relative;

  & > img:first-child {
    width: 100%;
    aspect-ratio: 1/0.74;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img:first-child {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.ondocInfoWrap {
  padding: 32px 21px;
  background-color: #f8f9fd;
  margin-top: 32px;
  .infoSlider {
    position: relative;
    .handleBtn {
      position: absolute;
      left: 0px;
      bottom: 170px;
      width: 30px;
      height: 30px;
      z-index: 1;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      &.rightHandleBtn {
        left: unset;
        right: 0px;
      }
    }
    .slide {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #1c1c1c;
        span {
          color: #ff8e26;
        }
      }
      img {
        width: 100%;
        aspect-ratio: 1/1;
        max-width: 245px;
        object-fit: cover;
        filter: drop-shadow(0px 2px 15px rgba(19, 28, 49, 0.15));
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -1px;
        color: rgba(28, 28, 28, 0.5);
      }
    }
  }

  // ul {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 12px;
  //   li:first-child {
  //     background: linear-gradient(
  //       305.8deg,
  //       rgba(63, 97, 215, 0.51) 7.36%,
  //       rgba(224, 233, 255, 0.51) 95.77%
  //     );
  //     img {
  //       width: 154px;
  //       height: 136px;
  //       object-fit: cover;
  //       object-position: right;
  //     }
  //   }
  //   li:nth-child(2) {
  //     background: linear-gradient(
  //       305.8deg,
  //       rgba(75, 56, 197, 0.51) 7.36%,
  //       rgba(238, 224, 255, 0.51) 95.77%
  //     );
  //     img {
  //       width: 166px;
  //       height: 148px;
  //       object-fit: cover;
  //       object-position: right;
  //     }
  //   }
  //   li:last-child {
  //     background: linear-gradient(
  //       305.8deg,
  //       rgba(111, 179, 100, 0.51) 7.36%,
  //       rgba(240, 255, 224, 0.51) 95.77%
  //     );
  //     img {
  //       width: 160px;
  //       height: 148px;
  //       object-fit: cover;
  //       object-position: right;
  //     }
  //   }
  //   li {
  //     position: relative;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //     padding: 0 10px 0px;
  //     gap: 15px;
  //     aspect-ratio: 1/0.75;
  //     border-radius: 16px;
  //     box-sizing: border-box;
  //     img {
  //       top: 0;
  //       right: 0;
  //       position: absolute;
  //     }
  //     h3 {
  //       position: relative;
  //       z-index: 1;
  //       font-weight: 700;
  //       font-size: 24px;
  //       line-height: 29px;
  //       color: var(--theme-font-color);
  //     }
  //     p {
  //       position: relative;
  //       z-index: 1;
  //       font-weight: 400;
  //       font-size: 15px;
  //       line-height: 18px;
  //       letter-spacing: 0.4px;
  //       color: var(--theme-font-color);
  //       padding-bottom: 20px;
  //     }
  //   }
  // }
}
.doctorInfoWrap {
  background: #fff3e8;
  background-size: cover;
  padding: 20px 0px 43px;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: var(--theme-font-color);
    padding: 10px 21px;
    text-align: center;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-font-color);
    padding: 4px 21px;
    text-align: center;
  }

  .doctorSliderWrap {
    position: relative;
    .doctorSlider {
      margin-top: 32px;
      padding: 0 21px;
      position: relative;
      .handleBtn {
        position: absolute;
        left: 21px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        z-index: 1;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        &.rightHandleBtn {
          left: unset;
          right: 21px;
        }
      }
      .doctorSlide {
        background: #ffffff;
        padding: 69px 0 27px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .doctorImageWrap {
          width: 100%;
          max-width: 224px;
          aspect-ratio: 1/1;
          border-radius: 50%;
          background: #f8f9fd;
          overflow: hidden;
          display: flex;
          justify-content: center;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;
            &.bigImage {
              object-position: top;
            }
          }
        }
        .doctorInfo {
          margin-top: 25.5px;
          padding: 0 17px;
          box-sizing: border-box;
          width: 100%;
          & > h4 {
            font-weight: 800;
            font-size: 20px;
            line-height: 27px;
            color: var(--theme-font-color);
            text-align: center;
            margin-bottom: 1px;
          }
          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--theme-font-color);
            display: inline-block;
            padding-bottom: 2px;
            width: 100%;
            text-align: center;
            margin-bottom: 24.5px;
          }
          & > ul {
            display: flex;
            flex-direction: column;
            padding: 10px;
            border-top: 1px solid rgba(28, 28, 28, 0.5);
            width: 100%;
            box-sizing: border-box;
            height: 134px;
            li {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: var(--theme-font-color);
            }
          }
        }
      }
    }
    .doctorSliderPagination {
      position: absolute;
      bottom: 186px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: var(--theme-font-color);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid var(--theme-color);
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}
.ondocService {
  position: relative;
  padding: 20px 0;
  background-color: #f8f9fd;
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #1c1c1c;
    padding: 10px 0;
  }
  p {
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #1c1c1c;
    padding: 10px 0;
  }
}
