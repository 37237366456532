.container {
  box-sizing: border-box;
  overflow: hidden;
}
.askWrap {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 10px 0 10px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    transition: all 0.5s;
  }
  &.isOpen::after {
    width: 100%;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-font-color);
    position: relative;
    z-index: 1;
  }
  &.isOpen span {
    color: white;
  }
  img {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
}

.answerWrap {
  background-color: white;
  max-height: 0;
  transition: max-height 0.5s, padding 0.5s;
  overflow: hidden;
  padding: 0 12px;
  &.isOpen {
    padding: 20.5px 12px;
    max-height: 500px;
  }
  * {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: var(--theme-font-color);
  }
}
