.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  &.isMobile {
  }
  .modalWrap {
    position: absolute;
    top: 50%;
    // right: 15px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: #fff;
    min-width: 333px;
    width: 333px;
    max-height: 280px;
    aspect-ratio: 333/280;
    .closeWrap {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: -30px;
      color: #fff;
    }
    .textWrap {
      padding: 44px 27px 20px 27px;
      text-align: center;
      & > div {
        margin-bottom: 8px;
        span {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }
        span:first-child {
          color: #131c31;
        }
        .hitText {
          box-shadow: inset 0 -15px 0 #fff3e7;
        }
        .hitText,
        span:last-child {
          color: var(--theme-color);
        }
      }
      & > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
    }
    .borderWrap {
      content: '';
      position: relative;
      left: 50%;
      transform: translate(-50%);
      width: 40px;
      height: 1px;
      background: #d9d9d9;
    }
    .alertWrap {
      padding: 20px 0 0 0;
      text-align: center;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666;
      }
      img {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
      }
    }
    .btnWrap {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      border-radius: 0 0 32px 32px;
      background-color: var(--theme-color);
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: #fff;
    }
  }
}

:global(.pc) {
  .modalContainer {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .modalWrap {
    width: 279px;
    height: 340px;
    left: 50%;
    transform: translate(-50%, -50%);
    .challengeSubmitInfoWrap {
      margin-left: 25px;
      div {
        margin-right: 20px;
      }
    }
  }
}
