.container {
  position: relative;
}
.processWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: url('../../assets/processBg.png');
  background-size: cover;
  padding: 38px 0 69px;
  margin-bottom: 32px;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #1c1c1c;
    padding: 10px 0;
    margin: 0;
  }
  & > p {
    padding: 4px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1c1c;
  }
  .hashtag {
    margin-top: 17px;
    display: flex;
    gap: 17px;
    li {
      padding: 8px 18px;
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processContentWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 39px;
    padding: 0 29px;
    box-sizing: border-box;
    & > div {
      width: calc(50% - 10px);
      aspect-ratio: 1/0.922;
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: #f8f9fd;
      border-radius: 16px;
      padding-bottom: 6px;
      box-sizing: border-box;
      &:first-child {
        padding-top: 39px;
        img {
          width: 145px;
          height: 132px;
        }
      }
      &:nth-child(2) {
        padding-top: 22px;
        img {
          width: 196px;
          height: 145px;
        }
      }
      &:nth-child(3) {
        padding-top: 30px;
        img {
          width: 142px;
          height: 144px;
        }
      }
      &:nth-child(4) {
        padding-top: 42px;
        img {
          width: 150px;
          height: 129px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -0.5px;
          color: #1c1c1c;
          text-align: center;
        }
        h4 {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #1c1c1c;
          padding: 10px;
        }
        p {
          height: 56px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          // align-items: f;
          text-align: center;
          letter-spacing: -0.5px;
          color: #1c1c1c;
        }
      }
    }
  }
  // .processSliderWrap {
  //   position: relative;
  //   width: 100%;
  //   .processSlider {
  //     margin-top: 32px;
  //     .processSlide {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       cursor: grab;
  //       & > img {
  //         width: 597px;
  //         height: 356px;
  //         object-fit: cover;
  //         border-radius: 16px;
  //       }
  //       .stage {
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;
  //         margin-top: 32px;
  //         h4 {
  //           font-weight: 700;
  //           font-size: 24px;
  //           line-height: 29px;
  //           letter-spacing: -0.5px;
  //           color: #1C1C1C;
  //           padding: 10px 0;
  //         }
  //         p {
  //           font-weight: 400;
  //           font-size: 15px;
  //           line-height: 18px;
  //           letter-spacing: -0.5px;
  //           color: #1C1C1C;
  //           padding: 4px 0;
  //         }
  //       }
  //     }
  //   }
  //   .processSliderPagination {
  //     position: absolute;
  //     bottom: 75px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     height: 32px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     li {
  //       width: 12px;
  //       height: 12px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       position: relative;
  //       div {
  //         width: 4px;
  //         height: 4px;
  //         background-color: var(--theme-color);
  //         border-radius: 50%;
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //       }
  //       &::after {
  //         transition: all 0.2s;
  //         scale: 0;
  //         content: '';
  //         display: block;
  //         width: 8px;
  //         height: 8px;
  //         border: 1px solid var(--theme-color);
  //         box-sizing: border-box;
  //         border-radius: 50%;
  //         transform-origin: center center;
  //       }
  //       &.hit::after {
  //         scale: 1;
  //       }
  //     }
  //   }
  // }
}
.prescriptionProductWrap {
  padding-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .prescriptionProductSlider {
    padding-left: 21px;
    .prescriptionProductSlide {
      cursor: pointer;
      & > img {
        width: 100%;
        aspect-ratio: 1/1.2;
        object-fit: cover;
        border-radius: 16px;
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.normalProductWrap {
  padding: 32px 21px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  .normalProductList {
    display: flex;
    margin-top: 16px;
    li {
      padding-bottom: 14px;
      cursor: pointer;
      width: 100%;
      .productThumbnailWrap {
        width: 100%;
        // border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        // aspect-ratio: 1/0.57;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.004em;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .hashtagList {
          padding-bottom: 10px;
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.allProductList {
  padding: 0 21px 64px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1c1c1c;
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 37px;
    li {
      width: calc(50% - 19px);
      padding-bottom: 20px;
      cursor: pointer;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          aspect-ratio: 1/1.2;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.004em;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #1c1c1c;
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: #1c1c1c;
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 64px;
    cursor: pointer;
  }
}
.faqWrap {
  padding: 32px 21px 64px;
  // background: #f8f9fd;
  background: #fff3e8;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.legitScript {
  position: relative;
  & > img {
    width: 100%;
    aspect-ratio: 1/0.327;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      color: #ffffff;
    }
  }
}
.ondocInfo {
  padding: 0 20px 64px;
  .processSlider {
    padding-top: 91px;
    position: relative;

    z-index: 1;
    .handleBtn {
      position: absolute;
      left: 20px;
      bottom: 129px;
      width: 30px;
      height: 30px;
      z-index: 1;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      &.rightHandleBtn {
        left: unset;
        right: 20px;
      }
    }
    .processSlide {
      display: flex;
      justify-content: center;
      height: 300px;
      background: #f8f9fd;
      gap: 38px;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        h3 {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: #1c1c1c;
          span {
            color: #ff8e26;
          }
        }
        p {
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.4px;
          color: rgba(28, 28, 28, 0.5);
        }
      }
      .processImageWrap {
        width: 280px;
        height: 279px;
        position: relative;
        top: -26px;
        filter: drop-shadow(0px 2px 15px rgba(19, 28, 49, 0.15));
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.doctorInfoWrap {
  background-size: cover;
  padding: 64px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #1c1c1c;
    padding: 10px 21px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1c1c;
    padding: 4px 21px;
  }
  .doctorSliderWrap {
    position: relative;
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 64px;
    .doctor {
      width: 100%;
      height: 100%;
      // flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff3e8;
      padding: 51px 34px 27px;
      box-sizing: border-box;

      .doctorImageWrap {
        width: 224px;
        height: 224px;
        border-radius: 50%;
        overflow: hidden;
        background-color: white;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }
      h4 {
        font-family: 'Manrope';
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        color: #1c1c1c;
        margin-top: 43.5px;
        text-align: center;
      }
      h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #1c1c1c;
        margin: 1px 0 24px;
        text-align: center;
      }
      ul {
        padding: 10px;
        border-top: 1px solid rgba(28, 28, 28, 0.5);
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #1c1c1c;
        }
      }
    }
    // .doctorSlider {
    //   width: 100%;
    //   padding: 0 20px;
    //   margin-top: 39px;
    //   box-sizing: border-box;
    //   .doctor {
    //     width: 100%;
    //     .doctorImageWrap {
    //       background-color: white;
    //       width: 100%;
    //       margin-bottom: 32px;
    //       text-align: center;
    //       border-radius: 16px;
    //       overflow: hidden;
    //       img {
    //         width: 224px;
    //         height: 259px;
    //         object-fit: cover;
    //         border-radius: 16px;
    //         &.bigImage {
    //           object-position: top;
    //         }
    //       }
    //     }
    //     .doctorInfo {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       h4 {
    //         font-family: 'Manrope';
    //         font-style: normal;
    //         font-weight: 800;
    //         font-size: 20px;
    //         line-height: 27px;
    //         color: #1c1c1c;
    //         padding: 2px 0 1px;
    //       }
    //       & > span {
    //         font-weight: 400;
    //         font-size: 14px;
    //         line-height: 17px;
    //         color: #1c1c1c;
    //         display: inline-block;
    //         padding-bottom: 2px;
    //       }
    //       ul {
    //         padding: 10px 0 21px;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         li {
    //           font-weight: 400;
    //           font-size: 14px;
    //           line-height: 17px;
    //           color: #1c1c1c;
    //         }
    //       }
    //     }
    //   }
    // }
    // .doctorSliderPagination {
    //   position: absolute;
    //   bottom: 198px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   height: 32px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   li {
    //     width: 12px;
    //     height: 12px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     div {
    //       width: 4px;
    //       height: 4px;
    //       background-color: var(--theme-color);
    //       border-radius: 50%;
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //     }
    //     &::after {
    //       transition: all 0.2s;
    //       scale: 0;
    //       content: '';
    //       display: block;
    //       width: 8px;
    //       height: 8px;
    //       border: 1px solid #1c1c1c;
    //       box-sizing: border-box;
    //       border-radius: 50%;
    //       transform-origin: center center;
    //     }
    //     &.hit::after {
    //       scale: 1;
    //     }
    //   }
    // }
  }
}

.ondocService {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: #f8f9fd;
  padding: 64px 0 70px;
  p:first-child {
    padding: 10px 0;
    font-weight: 700;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
    color: #1c1c1c;
  }
  p:last-child {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #1c1c1c;
  }
}
