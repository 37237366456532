.container {
  background: #f8f9fd;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}
.reviewImageWrap {
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}
.reviewInfoWrap {
  padding: 5px 10px 20px;
}
.reviewInfoTop {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-right: 13px;
    max-width: calc(100% - 129px);
    .userId {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: var(--theme-font-color);
      padding: 4px 0;
    }
    .userInfo {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #abb1bb;
      padding: 4px 0;
    }
    .reviewScore {
      display: flex;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .right {
    filter: drop-shadow(1px 6px 6px rgba(35, 47, 76, 0.04));
    transform: translateY(-27px);
    width: 116px;
    height: 116px;
    img {
      width: 116px;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}
.reviewInfoBottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme-font-color);
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 57px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
