.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  max-width: 280px;
  filter: drop-shadow(0px 2px 17px rgba(0, 0, 0, 0.4));
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  .content {
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  // .productThumbnail {
  //   width: 100%;
  //   transform: translateY(-50%);
  //   // margin-top: -50%;
  //   margin-bottom: -34%;
  //   img {
  //     width: 100%;
  //   }
  // }
  .closeBtn {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    width: fit-content;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    border: 1px solid rgba(1, 110, 63, 1);
    border-radius: 34px;
    margin: 10px auto 0;
    color: rgba(1, 110, 63, 1);
  }
  .priceWrap {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .price {
      padding: 2px 0;
      span {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(1, 110, 63, 0.6);
        &:last-child {
          text-decoration: line-through;
        }
      }
    }
    .discountedPrice {
      padding: 2px 0;
      span {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        color: rgba(1, 110, 63, 1);
      }
    }
  }
  .btns {
    display: flex;
    // margin-top: 27px;
    button {
      flex-basis: 50%;
      height: 44px;
      cursor: pointer;
    }
    .closeBtn {
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      background-color: white;
      border: 1px solid rgba(1, 110, 63, 1);
      color: rgba(1, 110, 63, 1);
    }
  }
}

.detailBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1/0.19;
  cursor: pointer;
  opacity: 0;
}
