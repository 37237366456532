.container {
  background-color: #f6f7f9;
  padding: 0 21px 160px;
  &.isMobile {
    padding: 0;
    .contentArea {
      gap: 0;
      & > .left {
        padding: 0 21px;
        .stepTitleWrap {
          padding: 21px;
          width: 100%;
          transform: translateX(-21px);
          background-color: #fff;
        }
        .orderTitleWrap {
          padding: 30px 21px 12px 21px;
          width: 100%;
          transform: translateX(-21px);
          background-color: #fff;
        }
        .productListWrap {
          margin-top: 21px;
          margin-bottom: 0;
        }
        .questionnaireWrap {
          margin-top: 21px;
        }
      }
    }
  }
}
.contentArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  .prevWrap {
    padding: 18px 21px;
    background: #fff;
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > .left {
    max-width: 100%;
    flex-shrink: 0;
    .stepTitleWrap {
      padding: 21px 0;
      display: flex;
      justify-content: space-between;
      & > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }
      .stepWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        & > span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #999;
        }
        & > div {
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          background: #d9d9d9;
          border-radius: 50%;
        }
        .hitStep {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          font-weight: 500;
          font-size: 10px;
          color: #fff;
          background: #131c31;
        }
      }
    }
    .orderTitleWrap {
      padding: 30px 0 12px 0;
      display: flex;
      gap: 4px;
      img {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
      }
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .right {
    flex-grow: 1;
    position: sticky;
    top: 200px;
    height: fit-content;
  }
}
.appointmentWrap {
  position: relative;
  z-index: 1;
  & > h3 {
    padding: 50px 0 45px 12px;
    color: #232323;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #e5e5e5;
  }
  .hospitalInfoWrap {
    margin-top: 10px;
    background-color: white;
    padding: 24px 20px 20px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    background: #fff;
    .hospitalDetail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        color: #232323;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.5px;
        padding: 8px 0;
      }
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          color: #000;
          font-size: 15px;
          font-weight: 500;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .hospitalAddress {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      h6 {
        color: rgba(19, 28, 49, 0.5);
        font-size: 15px;
        font-weight: 500;
      }
      .addressDetail {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        & > span {
          color: #131c31;
          font-size: 15px;
          font-weight: 400;
        }
        .copyBtn {
          cursor: pointer;
          svg {
            width: 14px;
            height: 14px;
          }
          span {
            color: #ff5656;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .appointmentDateWrap {
    padding: 20px 15px;
    background-color: white;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    background: #fff;
    .label {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .left {
        flex-shrink: 0;
        padding-top: 15.5px;
        span {
          color: rgba(19, 28, 49, 0.5);
          font-size: 15px;
          font-weight: 500;
          &:first-child {
            color: #ff5656;
          }
        }
      }
      .right {
        flex-grow: 1;
        .dropboxWrap {
          position: relative;
          .dropbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e5e5e5;
            background: #fff;
            padding: 15.5px 10px;
            cursor: pointer;
            position: relative;
            span {
              color: #abb1bb;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.5px;
            }
            svg {
              width: 22px;
              height: 22px;
            }
          }
          .calendar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            background-color: red;
            transform: translateY(100%);
          }
        }
        .appointmentInfo {
          padding: 14px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f6f7f9;
          margin-top: 10px;
          span {
            color: #131c31;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.5px;
          }
          svg {
            cursor: pointer;
          }
        }
        p {
          color: rgba(19, 28, 49, 0.5);
          font-size: 11px;
          font-weight: 500;
          padding: 7px 4px;
        }
      }
    }
  }
}
:global(.pc) .appointmentWrap .appointmentDateWrap .label {
  flex-direction: row;
}

.productListWrap {
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 16px;
  .productInfoWrap {
    padding: 25px 12px;
    display: flex;
    .thumbnailWrap {
      width: 96px;
      height: 96px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .productInfo {
      width: 100%;
      .productName {
        padding: 6px 10px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: var(--theme-font-color);
      }
      .optionList {
        li {
          display: flex;

          span {
            padding: 7px 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
      .totalInfoWrap {
        display: flex;
        gap: 6px;
        padding: 6px 8px;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
    }
  }
}
.authSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.authLabel {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.labelWrap {
  display: flex;
  align-items: center;
  gap: 1px;
}

.labelWrap span {
  margin-bottom: 3px;
}

.labelWrap span:first-child {
  font-weight: bold;
  color: #ff5656;
}

.labelWrap span:last-child {
  font-weight: bold;
  font-size: 16px;
}

.authLabel p {
  font-size: 14px;
  color: #666;
  margin-bottom: 3px;
}

.authButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border: 2px solid #ccc;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
}

.authButton:hover {
  border-color: var(--theme-color);
}

.authenticated {
  background: #fce4ec;
  border-color: #d81b60;
  color: #d81b60;
}

.radioButton {
  width: 16px;
  height: 16px;
  border: 1.5px solid #ccc;
  border-radius: 50%;
  background: #fff;
  margin-right: 8px;
  position: relative;
}

.radioButton.checked {
  border-color: #d81b60;
  background: #d81b60;
}

.radioButton.checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrowIcon {
  font-size: 16px;
  color: inherit;
}
.questionnaireWrap {
  padding: 16px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    & > span:first-child {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
    }
    label {
      display: flex;
      align-items: center;
      gap: 5.5px;
      padding: 13px 0;
      cursor: pointer;
      input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1.5px solid #6e6d79;
        border-radius: 3px;
        &.checked {
          background-color: #3559ed;
          border: 1.5px solid #3559ed;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 5px;
            height: 7px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            left: 2.5px;
            top: 0px;
          }
        }
      }
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
    }
  }
  .questionnaireForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .phoneNumberLabel {
      .labelWrap {
        padding: 0 0 8px 0;
        display: flex;
        gap: 1px;
        & > span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        & > span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #666;
        }
      }
      div:last-child {
        .phoneNumberInuptWrap {
          // gap: 17px;
          position: relative;
          span {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(19, 28, 49, 0.5);
          }
          input {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            width: 100%;
            height: 48px;
            box-sizing: border-box;
            padding-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: var(--theme-font-color);
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type='number'] {
              -moz-appearance: textfield;
            }
          }
        }
        p {
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #999;
          padding: 4px 0;
        }
      }
    }
    .securityNumberLabel {
      .labelWrap {
        padding: 0 0 8px 0;
        display: flex;
        gap: 1px;
        & > span:first-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ff5656;
        }
        & > span:last-child {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #666;
        }
      }
      div:last-child {
        .securityNumberInuptWrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // gap: 17px;
          position: relative;
          span {
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(19, 28, 49, 0.5);
          }
          input {
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            height: 48px;
            flex-grow: 1;
            max-width: calc(50% - 8px);
            box-sizing: border-box;
            padding-left: 10px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: var(--theme-font-color);
            &.fullWidth {
              max-width: 100%;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type='number'] {
              -moz-appearance: textfield;
            }
          }
        }
        p {
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #999;
          padding: 4px 0;
          word-break: keep-all;
        }
      }
    }
    .nameLabel {
      p {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        color: #999;
        padding: 4px 0;
        max-width: 300px;
        word-break: keep-all;
      }
    }
  }
  .pregnancyLabel {
    &.warning > div:first-child > span:last-child {
      color: #ff5656;
    }
    & > div:first-child {
      padding: 15px 0;
      display: flex;
      gap: 1px;
      margin-right: 30px;
      & > span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
      & > span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #666;
      }
    }
    .checkOutWrap {
      display: flex;
      gap: 8px;
    }
    .customCheckBoxWrap {
      padding: 14px 0 14px 16px;
      display: flex;
      gap: 12px;
      cursor: pointer;
      flex-grow: 1;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      .customCheckBox {
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border: 1.5px solid #e5e5e5;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      &.hit {
        border: 2px solid var(--theme-color);
        background: #fff3e7;
        .customCheckBox {
          background: #fff;
          border: 1.5px solid var(--theme-color);
          &::after {
            background-color: var(--theme-color);
          }
        }
        span {
          color: var(--theme-font-color);
        }
      }
    }
  }
}
.prescriptionDetailWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 21px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .label {
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    &.warning {
      .labelWrap > span:last-child {
        color: #ff5656;
      }
    }
    .labelWrap {
      // width: 93px;
      width: 100% !important;
    }
    &.caffeineSensitivity {
      .checkboxArea {
        gap: 8px;
        justify-content: start;
        .customCheckBoxWrap {
        }
      }
    }
    .sideEffects {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      resize: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
    .sideEffects::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #999;
    }
    .checkboxArea {
      display: flex;
      justify-content: space-between;
      &.isMobile {
        .customCheckBoxWrap {
          padding: 14px 0;
          flex-direction: column;
          gap: 4px;
          height: 72px;
        }
      }
      .customCheckBoxWrap {
        width: calc(100% / 4 - 8px);
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        border-radius: 5px;
        padding: 14px 0 14px 16px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        &.hit {
          border: 2px solid var(--theme-color);
          background: #fff3e7;
          .customCheckBox {
            background: #fff;
            border: 1.5px solid var(--theme-color);
            &::after {
              background-color: var(--theme-color);
            }
          }
          span {
            color: var(--theme-font-color);
          }
        }
        &.hitCheck {
          border: 2px solid var(--theme-color);
          background: #fff3e7;
          .customCheckBox {
            background: transparent;
            border: 2px solid var(--theme-color);
            &::after {
              background: url('../../assets/question/check.png');
              background-size: 16px;
              aspect-ratio: 1/1;
              width: 16px;
              height: 16px;
            }
          }
          span {
            color: var(--theme-font-color);
          }
        }
        .customCheckBox {
          box-sizing: border-box;
          width: 16px;
          height: 16px;
          border: 1.5px solid #e5e5e5;
          border-radius: 50%;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: rgba(19, 28, 49, 0.5);
        }
      }
    }
  }
}
.diseaseCheckWrap,
.medicineCheckWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 21px;
  padding: 0 16px;
  &.warning {
    border: 1px solid #ff5656;
    .checkArea > div:first-child > span:last-child {
      color: #ff5656;
    }
  }
  .checkArea {
    padding: 16px 0;
    & > div:first-child {
      margin-bottom: 8px;
      display: flex;
      gap: 1px;
      width: 93px;
      & > span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ff5656;
      }
      & > span:last-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #666;
      }
    }
    .checkOutWrap {
      display: flex;
      gap: 8px;
    }
    .customCheckBoxWrap {
      padding: 14px 0 14px 16px;
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;
      flex-grow: 1;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      .customCheckBox {
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border: 1.5px solid #e5e5e5;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.5);
      }
      &.hit {
        border: 2px solid var(--theme-color);
        background: #fff3e7;
        .customCheckBox {
          background: #fff;
          border: 1.5px solid var(--theme-color);
          &::after {
            background-color: var(--theme-color);
          }
        }
        span {
          color: var(--theme-font-color);
        }
      }
    }
  }
  .sectionGuide {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #666;
    display: inline-block;
    padding: 15px 0;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 13px 8px;
    width: 100%;
    height: 170px;
    box-sizing: border-box;
    resize: none;
    margin-bottom: 20px;
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #999;
    }
  }
}
.label {
  .labelWrap {
    padding: 0 0 8px 0;
    display: flex;
    gap: 1px;
    & > span:first-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
    }
    & > span:last-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #666;
    }
  }
  .inputWrap {
    position: relative;
    .reason {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      resize: none;
    }
    .inputUnit {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
    }
    .normalInput {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding-left: 10px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #999;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
}
.cautionWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 21px;
  padding: 16px;
  & > .labelWrap {
    padding: 0 0 8px 0;
    display: flex;
    span:first-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ff5656;
    }
    span:last-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #666;
    }
  }
  ul {
    li {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666;
      margin-bottom: 8px;
    }
    li:last-child {
      margin: 0;
    }
  }
  .confirm {
    padding: 14px 0 14px 16px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 12px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.5);
    }
    .customCheckBox {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border: 1.5px solid #e5e5e5;
      border-radius: 50%;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
    &.hit {
      span {
        color: var(--theme-font-color);
      }
      border: 2px solid var(--theme-color);
      background: #fff3e7;
      .customCheckBox {
        background: #fff;
        border: 1.5px solid var(--theme-color);
        &::after {
          background-color: var(--theme-color);
        }
      }
    }
  }
  &.isError {
    border: 1px solid #ff5656;
    ul li {
      color: #ff5656;
    }
  }
}

.btnWrap {
  margin-top: 16px;
  &.isMobile {
    margin-top: 21px;
    background: #fff;
    display: flex;
    align-items: center;
    width: calc(100% + 42px);
    height: 72px;
    transform: translateX(-21px);
    .submitBtn {
      width: calc(100% - 42px);
      transform: translateX(21px);
    }
  }
}

.submitBtn {
  align-items: center;
  background: var(--theme-color);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 52px;
  justify-content: center;
  line-height: 24px;
  width: 100%;
  outline: none;
  border: none;
}
.validationError,
.securityNumberInuptWrap.validationError {
  position: relative;
  .inputUnit {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    background: url('../../assets/error.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  input,
  textarea {
    border: 1px solid #ff5656 !important;
    &::placeholder {
      color: #ff5656 !important;
    }
  }
}

.optionWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .option {
    display: flex;
    align-items: center;
    & > span:first-child {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #6e6d79;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      box-sizing: border-box;
      flex-shrink: 0;
      margin-right: 10px;
    }
    .optionBox {
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid #d1d0d5;
      display: flex;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      // padding: 0px 21px 0px 0;
      cursor: pointer;
      position: relative;
      .optionList {
        position: absolute;
        bottom: 0;
        left: -1px;
        right: -1px;
        transform: translateY(100%);
        z-index: 1;
        li {
          height: 46px;
          display: flex;
          align-items: center;
          border: 1px solid #d1d0d5;
          border-bottom: none;
          background-color: white;
          padding-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #131c31;
          &:last-child {
            border-bottom: 1px solid #d1d0d5;
          }
        }
      }
      & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.5px;
        color: #abb1bb;
        padding: 9.5px 10px;
      }
      & > img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.selectedOptionListWrap {
  padding: 0 20px 10px 20px;
  li > div {
    display: flex;
    align-items: center;
    height: 62px;
    padding-right: 16px;
    background: #f6f7f9;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    &.isMobile {
      .optionName {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
      .counter {
        width: 90px;
        margin-right: 4px;
      }
      .editBox > span:first-child {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .optionName {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #131c31;
      padding: 10px;
      width: 350px;
      box-sizing: border-box;
    }
    .counter {
      display: flex;
      width: 100px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 24px;
      box-sizing: border-box;
      flex-shrink: 0;
      margin-right: 10px;
      & > div {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
      .minus,
      .plus {
        cursor: pointer;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .editBox {
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: auto;
      .optionPrice {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        justify-content: flex-end;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.5px;
          color: var(--theme-font-color);
          &:first-child {
            text-decoration: line-through;
            color: #abb1bb;
          }
        }
      }
      img {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
    }
  }
}

.totalPriceWrap {
  border-top: 1px solid #f6f7f9;
  padding: 10px 0;
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .head {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #6e6d79;
    padding: 6px 16px 6px 10px;
  }
  .totalPrice {
    display: flex;
    align-items: flex-end;
    padding: 3px 0;
    & > span:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #131c31;
    }
    & > span:last-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #131c31;
    }
  }
}
