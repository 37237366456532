.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.2);
  &.isDesktop {
    max-width: 850px;
    .contentWrap {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 808px;
      height: 600px;
      border-radius: 0;
      .mainWrap {
        padding: 60px 0 80px;
      }
    }
  }
  .contentWrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 80px);
    box-sizing: border-box;
    border-radius: 24px 24px 0 0;
    background: #fff;
    @media screen and (max-height: 750px) {
      height: 600px;
    }
    .slideWrap {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      img {
        width: 49px;
        height: 4px;
      }
    }
    .closeWrap {
      position: absolute;
      right: 10px;
      top: -50px;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
    .mainWrap {
      padding: 60px 0;
      .topWrap {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 120px;
        .imgWrap {
          img {
            width: 140px;
            height: 140px;
          }
        }
        .alertWrap {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          .hit {
            color: #ff7f00;
          }
        }
        & > span {
          font-weight: 400;
          font-size: 17px;
          line-height: 24px;
          color: #666;
        }
      }
      .barWrap {
        padding: 0 70px;
        display: flex;
        gap: 12px;
        flex-direction: column;
        align-items: center;
        .progressBar {
          width: 100%;
          height: 8px;
          background-color: #e0e0e0;
          border-radius: 5px;
          overflow: hidden;
          position: relative;
        }
        .progress {
          height: 100%;
          background-color: #ff7f00;
          transition: width 0.3s ease;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
