.container,
.couponContainer {
  height: 58px;
  background-color: rgba(17, 44, 0, 1);
  position: fixed;
  top: 140px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  // transform: translateX(-50%);
  border-radius: 40px;
  padding: 0 12px 0 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 98;
  & > * {
    flex-shrink: 0;
  }
}
.productThumbnailWrap {
  width: 89px;
  height: 52px;
  flex-shrink: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    // height: 100%;
  }
}
.productName {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 10px;
  color: #ffffff;
}
.comment {
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  color: rgba(255, 255, 255, 0.5);
  padding: 6px 4px;
}
.priceWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  .price {
    // display: flex;
    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      &:last-child {
        text-decoration: line-through;
      }
    }
  }
  .discountedPrice {
    span {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
    }
  }
}
.productDetailBtn {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  margin-left: 16px;
  img {
    width: 24px;
    height: 24px;
  }
}
.mobileProductDetailBtn {
  width: 24px;
  height: 24px;
  margin-left: 14px;
  img {
    width: 100%;
    height: 100%;
  }
}
.isMobile {
  padding: 0 9px 0 11px;
  .productThumbnailWrap {
    width: 68px;
    height: 40px;
  }
  .productName {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 0 6px;
    color: #ffffff;
  }
  .priceWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    .price {
      line-height: 12px;
      span {
        font-size: 9px;
        font-weight: 500;
        line-height: 11px;
      }
    }
    .discountedPrice {
      line-height: 12px;
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
    }
  }
}
.couponContainer {
  background-color: rgba(1, 1, 3, 1);
}
