.container {
  background: #f6f7f9;
}
.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  cursor: pointer;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
  }
}

.orderInfoWrap {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  .orderHeader {
    padding: 21px 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    span:first-child {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #232323;
      white-space: nowrap;
    }
    span:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #999999;
    }
  }
  .orderProductInfoWrap {
    padding: 0 20px 20px;
    background-color: white;
    border-top: 1px solid #efefef;
    .sectionHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #f6f7f9;
      span:first-child {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #232323;
      }
      .orderCancelBtn,
      .deliveryCheck {
        margin-left: auto;
        margin-right: 7px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: rgba(19, 28, 49, 0.5);
        cursor: pointer;
      }
      .status {
        border: 1px solid #121842;
        border-radius: 100px;
        width: 80px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
      }
    }
    .productWrap {
      padding: 20px 0;
      display: flex;

      .thumbnailWrap {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        .productName {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.5px;
          color: #232323;
          padding: 5px 6px;
        }
        .option {
          padding: 0 6px;
          display: flex;
          gap: 4px;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(19, 28, 49, 0.5);
            padding: 4px 2px;
            white-space: nowrap;
          }
          ul {
            li {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: rgba(19, 28, 49, 0.5);
              padding: 4px 0;
            }
          }
        }
        .count {
          display: flex;
          gap: 4px;
          padding: 0px 6px;
          span:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(19, 28, 49, 0.5);
            padding: 4px 2px;
          }
          span:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(19, 28, 49, 0.5);
            padding: 4px 0;
          }
        }
      }
    }
    .deliveryNumber {
      // margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
        padding: 8px 10px;
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }
}
.refundWrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  background-color: white;
  padding: 0 21px;
  .refundHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #999999;
    padding: 15px 15px 52px;
  }
}

.addressInfoWrap,
.reservationInfoWrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  background-color: white;
  padding: 0 21px;
  .addressHeader,
  .reservationHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }
  .addressInfo,
  .reservationInfo {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 10px 16px 20px;
    & > div {
      width: 100%;
      display: flex;
      & > span:first-child {
        flex-basis: 65px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
        white-space: nowrap;
        flex-shrink: 0;
      }
      & > span:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
        display: flex;
        align-items: center;
        .mapBtn {
          margin-left: 6px;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            color: #000;
            font-size: 15px;
            font-weight: 500;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
        .copyBtn {
          margin-left: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          svg {
            width: 14px;
            height: 14px;
          }
          span {
            color: #ff5656;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      &.half {
        width: 50%;
        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }
}
.payInfoWrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  background-color: white;
  padding: 0 20px;
  .payHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }
  .priceCalculation {
    padding: 11px 16px 20px;
    border-bottom: 1px solid #efefef;
    & > div {
      display: flex;
      justify-content: space-between;
    }
    & > div:first-child {
      margin-bottom: 11px;
      span:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
      span:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #141736;
      }
    }
    & > div:nth-child(2) {
      margin-bottom: 10px;
      span:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
      span:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
    }
    & > div:nth-child(3) {
      span:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
      }
      span:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
    }
    .discountInfoList {
      display: flex;
      flex-direction: column;
      margin-top: 11px;
      gap: 11px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          display: flex;
          align-items: center;
          img {
            width: 8px;
            height: 8px;
            margin-right: 6px;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: #999999;
          }
        }
        & > span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
        }
      }
    }
  }
  .totalPrice {
    padding: 18px 15px 13px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
      span:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #ff3d3d;
      }
    }
    & > span {
      margin-top: 10px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.5px;
      color: #999999;
    }
  }
}
.refundPriceWrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  background-color: white;
  padding: 0 20px;
  .refundPriceHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }
  .refundCalculation {
    padding: 9px 16px 24px;
    border-bottom: 1px solid #efefef;
    & > div:first-child {
      display: flex;
      justify-content: space-between;
      span:first-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
      }
      span:last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.5px;
        color: #141736;
      }
    }
    .notRefundList {
      margin-top: 11px;
      display: flex;
      flex-direction: column;
      gap: 11px;
      li {
        display: flex;
        justify-content: space-between;
        span:first-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
        }
        span:last-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #999999;
        }
      }
    }
  }
  .totalRefundPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 15px 10px;
    span:first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #232323;
    }
    span:last-child {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #ff3d3d;
    }
  }
}

.payMethodWrap {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 22px;
  background-color: white;
  padding: 0 20px;
  .payMethodHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
  }
  span {
    display: inline-block;
    // padding: 10px 16px 11px;
    padding: 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #999999;
  }
  .payMethodListWrap {
    padding: 20px 0;
    display: flex;
    gap: 20px;
    flex-direction: column;
    span {
      padding: 0;
    }
    .paymethodFrame {
      display: flex;
      gap: 18px;
      .labelWrap {
        font-weight: 400;
        font-size: 15px;
        line-height: normal;
        letter-spacing: -0.5px;
        color: rgba(19, 28, 49, 0.5);
      }
      .contentWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        span {
          font-weight: 400;
          font-size: 15px;
          line-height: normal;
          letter-spacing: -0.5px;
          color: #131c31;
        }
        span:last-child {
          color: rgba(19, 28, 49, 0.5);
        }
      }
    }
  }
}

.refundGuide {
  text-align: right;
  padding: 0 20px 50px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #999999;
  }
}
