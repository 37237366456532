.contentWrap {
  background-color: #f6f7f9;
}

.prevWrap {
  background-color: #fff;
  padding: 8px 21px;
  img {
    width: 30px;
    height: 30px;
  }
}

.navigation {
  height: 46px;
  & > ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #efefef;
    position: relative;
    background-color: white;
    & > li {
      width: 86px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 23px;
      color: #131c31;
      cursor: pointer;
      z-index: 1;
      font-size: 16px;
      font-weight: 500;
    }
    .movingBar {
      position: absolute;
      bottom: 0;
      width: 0px;
      height: 3px;
      background: #131c31;
      transition: all 0.2s linear;
    }
  }
}

.active {
  margin-top: 1px;
  border-bottom: 1px solid #ff7f00;
  transition: border-bottom 0.2s ease-in-out;
}

.dateWrap {
  padding: 18px 30px;

  img {
    cursor: pointer;
    width: 37px;
    height: 37px;
  }

  &.isDesktop {
    background-color: #f6f7f9;
    padding: 50px 76px;
  }
  div {
    max-width: 70px;
    max-height: 22px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    padding: 5px;
    span {
      color: #131c31;
      font-size: 10px;
      font-weight: 500;
    }
  }
  h3 {
    margin: 8px 0;
    color: #232323;
    font-size: 20px;
    font-weight: 600;
    svg {
      margin-right: 10px;
      margin-bottom: 3px;
    }
    img {
      margin-bottom: 4px;
    }
  }
  p {
    color: #666;
    font-size: 15px;
    font-weight: 400;
  }
}

.listWrap {
  padding: 40px 33px;
  &.isWeekDesktop {
    padding: 0px 76px;
  }
  &.isDesktop {
    padding: 0px 76px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0px 80px;
    max-height: 330px;
  }
  .list {
    display: flex;
    border-top: 1px solid #e5e5e5;
    padding: 4px 3px;
    &.isDesktop {
      min-width: 300px;
    }

    .nowTitle {
      background-color: #ff7f00;
      border-radius: 50px;
    }

    .nowTitleP {
      color: #fff;
    }

    .notNowTitleP {
      color: #131c31;
    }

    .nowWeightSpan {
      color: #ff7f00;
    }

    div:nth-child(1) {
      min-height: 18px;
      min-width: 29px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      p {
        font-size: 15px;
        font-weight: 400;
      }
    }
    div:nth-child(2) {
      margin-left: 40px;
      flex-grow: 1;
      line-height: 40px;
      span {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.pointWrap {
  height: 187px;
  background-color: #fff;
  img {
    width: 24px;
    height: 24px;
  }
  &.isDesktop {
    min-width: 470px;
    margin-top: 120px;
    padding: 50px 191px;
    div:nth-child(1) {
      width: 100%;
      p:nth-child(1) {
        color: #666;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
      }
      p:nth-child(2) {
        color: #131c31;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }
    }
    div:nth-child(2) {
      button:nth-child(1) {
        min-width: 230px;
        max-height: 48px;
        background-color: #f6f7f9;
        border-radius: 5px;
        border: none;
        margin: 50px 10px 0 0;
        padding: 13px;
        span {
          color: #131c31;
          font-size: 16px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
      button:nth-child(2) {
        min-width: 230px;
        max-height: 48px;
        background-color: #f6f7f9;
        border-radius: 5px;
        border: none;
        margin: 50px 0 0 0px;
        padding: 13px;

        span {
          color: #131c31;
          font-size: 16px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
    }
  }

  &.isMobile {
    position: fixed;
    bottom: 62px;
    z-index: 1;
    width: 100%;
    div:nth-child(1) {
      padding: 34px 111px 0px 111px;
      p:nth-child(1) {
        color: #666;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
      }
      p:nth-child(2) {
        color: #131c31;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .weightBtnWrap {
      padding: 0px 33px 22px 33px;
      display: flex;
      gap: 11px;
      button:nth-child(1) {
        width: 100%;
        max-height: 48px;
        height: 48px;
        background-color: #f6f7f9;
        padding: 12px;
        margin-top: 32px;
        border-radius: 5px;
        text-align: center;
        border: none;
        span {
          color: #131c31;
          font-size: 16px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
      button:nth-child(2) {
        width: 100%;
        max-height: 48px;
        height: 48px;
        background-color: #f6f7f9;
        padding: 12px;
        margin-top: 32px;
        border-radius: 5px;
        text-align: center;
        border: none;
        span {
          color: #131c31;
          font-size: 16px;
          font-weight: 500;
          margin-left: 4px;
        }
      }
    }
  }
}

.mHeight {
  height: 187px;
}
