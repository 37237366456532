.contentWrap {
  width: 100%;
  aspect-ratio: 1/1.8;
  background: url('../../assets/companyInfoBg.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  padding-top: 89px;
  box-sizing: border-box;
  img {
    width: 150px;
    height: 130px;
  }
  p:nth-child(2) {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #ffffff;

    padding: 0 50px;
    word-break: keep-all;
  }
  p:last-child {
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #ffffff;
    padding: 0 50px;
    word-break: keep-all;
  }
}

:global(.pc) .contentWrap {
  aspect-ratio: 1/0.835;
  padding-top: 117px;
}
