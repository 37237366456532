.container {
  padding: 32px 20px 64px;
}
.bannerSliderWrap {
  position: relative;
}
.bannerSlider {
  // border-radius: 16px;
  img {
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
}
.paginationWrap {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 4px;
  z-index: 1;
  li {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 4px;
    transition: width 0.2s linear;
    cursor: pointer;
    &.hit {
      width: 24px;
    }
  }
}
