.onlyReturningPatients {
  background-color: #f4f5f9;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #6e6d79;
  width: 100%;
  &.isMobile {
    height: 52px;
    font-size: 14px;
    line-height: 17px;
  }
}
.opener {
  background-color: var(--theme-color);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  img {
    width: 30px;
    height: 30px;
  }
}

.container {
  background-color: white;
  padding: 35px 0 0;
  .logoArea {
    padding: 14px 20px;
    img {
      width: 150px;
      height: 27px;
      object-fit: cover;
    }
  }
}
.companyInfoWrap {
  padding: 0 20px 35px;
  .companyName {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #1c1c1c;
    padding: 16px 0;
  }
  .companyInfoLine {
    display: flex;
    gap: 1px;
    span {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #1c1c1c;
      display: inline-block;
      padding: 4px 2px;
      &.businessInfo {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.5px;
        color: rgba(28, 28, 28, 0.3);
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .copyright {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.5px;
    color: rgba(28, 28, 28, 0.5);
    display: block;
    padding: 4px 0;
  }
  .navigation {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    li {
      position: relative;
      padding: 4px 0;
      font-weight: 300;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: rgba(28, 28, 28, 0.5);
      cursor: pointer;
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background-color: var(--theme-color);
      }
      &:last-child::after {
        display: none;
      }
    }
  }
}

.ondocInfo {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #ffffff;
  background: var(--theme-color);
  padding: 40px 0;
}

.isMobile {
  .companyInfoWrap {
    padding: 20px 0 38px;
    .companyName {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-font-color);
      padding: 10px 0;
    }
    .companyInfoLine {
      flex-wrap: wrap;
      align-items: center;
      span {
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.5px;
        color: var(--theme-font-color);
        &:first-child {
          width: 100%;
        }
        &.businessInfo {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.5px;
          color: rgba(19, 28, 49, 0.3);
        }
      }
    }
    .copyright {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
    }
    .navigation {
      gap: 7px;
      margin-top: 28px;
      li {
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.5px;
        padding: 4px;
        &::after {
          height: 10px;
          right: -4px;
        }
      }
    }
  }
  .ondocInfo {
    padding: 30px 0;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.5px;
    color: #ffffff;
  }
}
