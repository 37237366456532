.categorySelectWrap {
  padding: 88px 0 0 0px;
  & > h3 {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.5px;
    color: #141736;
    margin-bottom: 10px;
  }
  .categoryList {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 4.5px 0;
    li {
      padding: 10px 15px;
      cursor: pointer;
      font-weight: 500;
      font-size: 20px;
      line-height: 19px;
      color: var(--theme-font-color);
      &.hit {
        font-weight: 700;
      }
    }
  }
}

.productList {
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  & > li {
    border-bottom: 7px solid #f4f4f4;
    margin-bottom: 20px;
    padding: 0 21px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    .thumbnailWrap {
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        aspect-ratio: 1/1.08;
        object-fit: cover;
      }
    }
    .hospitalName {
      margin-top: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #999999;
    }
    .productName {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.5px;
      color: #232323;
    }
    .hashTagList {
      margin-top: 10px;
      li {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
      }
    }
    .priceInfoWrap {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 11px;
      .price {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        text-decoration-line: line-through;
        color: #999999;
      }
      .discountPrice {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.5px;
        color: #141736;
      }
    }
  }
}
