@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Light.subset.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Regular.subset.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Bold.subset.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-ExtraBold.subset.woff2')
    format('woff2');
  font-weight: 800;
  font-style: normal;
}
body {
  margin: 0 auto;
  max-width: 850px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 64px); //네비게이션 바
  &.pc {
    min-width: 850px;
  }
}
* {
  font-family: 'Pretendard';
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  vertical-align: middle;
  // user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
:root {
  --theme-color: #ff7f00;
  --theme-font-color: #1c1c1c;
}
